<template>
  <div class="loginBack">
    <div class="loginBox">
      <div class="loginTitle">登录发现更多精彩</div>
      <div class="loginTab">
        <div
          class="tabClass"
          @click="tabEvent(item)"
          v-for="item in list"
          :key="item.id"
          :class="tabActive === item.id ? 'active' : ''"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="formInput">
        <div class="mobileStyle">
          <input
            class="mobileInp"
            v-model="loginForm.mobile"
            type="text"
            placeholder="请输入手机号"
          />
        </div>
        <div class="CodeStyle" v-if="tabActive === 1">
          <input
            class="CodeInp"
            v-model="loginForm.code"
            type="text"
            placeholder="请输入验证码"
          />
          <div class="codeDiv" v-if="isShow" @click="obtainCode()">
            获取验证码
          </div>
          <div class="codeColor" v-if="!isShow">{{ this.count }}s重新获取</div>
        </div>
        <div class="CodeStyle" v-if="tabActive === 2">
          <input
            class="CodeInp"
            ref="type"
            v-model="loginForm.password"
            type="password"
            placeholder="请输入密码"
          />
          <div class="showPassword">
            <img @click="inpType()" v-if="flag" src="../image/show1.png" alt />
            <img @click="inpType1()" v-if="!flag" src="../image/show.png" alt />
          </div>
        </div>
        <div class="blue" v-if="tabActive === 2" @click="goPass()">
          忘记密码
        </div>
      </div>
      <div class="loginBtn" v-if="btnStatus">登录</div>
      <div class="loginBtn1" v-if="!btnStatus" @click="loginEvent()">登录</div>
      <div class="registerBtn" @click="goRegister()">注册</div>
      <div class="other">
        <div></div>
        <div>其他登录方式</div>
        <div></div>
      </div>
      <div class="wxLogin">
        <img src="../image/wxLogin.png" alt @click="loginWithWechat()" />
      </div>
      <div class="footer">
        登录表示同意
        <span @click="like()">《隐私政策》</span> 和
        <span @click="like1()">《服务协议》</span>
      </div>
      <!-- <iframe :src="pdfSrc" frameborder="0" width="100%" height="100%"></iframe> -->
    </div>
  </div>
</template>

<script>
import { send, loginUser, userLogin } from "../api/user";
import { Toast } from "vant";
import Cookies from "js-cookie";
import store from "@/store";
export default {
  data() {
    return {
      // pdfSrc: "",
      list: [
        {
          id: 1,
          name: "手机号登录",
        },
        {
          id: 2,
          name: "密码登录",
        },
      ],
      tabActive: 1,
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0, //倒计时 计数器
      btnStatus: true,
      loginForm: {
        mobile: "", //手机号
        password: "", //密码
        code: "", //验证码
        openId: "", //微信
      },
      flag: true,
      sig: "",
    };
  },
  watch: {
    "loginForm.mobile"(newValue) {
      if (newValue.trim() != "") {
        this.btnStatus = false;
      } else {
        this.btnStatus = true;
      }
    },
  },
  mounted() {},
  methods: {
    tabEvent(item) {
      this.tabActive = item.id;
    },
    // 获取验证码
    obtainCode() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (!phoneReg.test(this.loginForm.mobile)) {
        Toast("请输入正确的手机号");
      } else {
        this.isShow = false;
        this.count = 5;
        var times = setInterval(() => {
          this.count--; //递减
          if (this.count <= 0) {
            // <=0 变成获取按钮
            this.isShow = true;
            clearInterval(times);
          }
        }, 1000); //1000毫秒后执行
        send({ mobile: this.loginForm.mobile, type: 2 }).then((res) => {
          console.log(res, "登录获取验证码");
        });
      }
    },
    // 登录
    loginEvent() {
      if (this.tabActive === 1) {
        if (this.loginForm.code == "") {
          Toast("请输入验证码");
        } else {
          this.getloginUser();
          this.$router.push({ path: "/lookIndex" });
        }
      } else if (this.tabActive === 2) {
        // Toast("请输入密码");
        userLogin(this.loginForm).then((res) => {
          if (res.data.code === 0) {
            // console.log(res, "密码登录111");
            // console.log(res.data.data.token);
            // if (res.data.data.circleAuth == 1) {
            // this.imLogin(res.data.data.id + "", res.data.data.imSig)
            // }
            Cookies.set("token", res.data.data.token);
            this.$store.commit("user", res.data.data);
            this.$router.push({ path: "/lookIndex" });
            Toast("登录成功");
          } else {
            Toast(res.data.msg);
          }
        });
      } else {
        // this.getloginUser();
      }
    },
    // 验证码登录
    getloginUser() {
      loginUser(this.loginForm).then((res) => {
        console.log(res, "登录");
        if (res.data.code === 0) {
          // if (res.data.data.circleAuth == 1) {
          //   this.imLogin(res.data.data.id + "", res.data.data.imSig)
          // }
          Cookies.set("token", res.data.data.token);
          this.$store.commit("user", res.data.data);
          Toast("登录成功");
          this.$router.push({ path: "/lookIndex" });
        }
      });
    },
    // 注册
    goRegister() {
      this.$router.push({ path: "/register" });
    },
    // 找回密码
    goPass() {
      this.$router.push({ path: "/findPassword" });
    },
    inpType() {
      this.flag = false;
      this.$refs.type.type = "text";
    },
    inpType1() {
      this.flag = true;
      this.$refs.type.type = "password";
    },
    like() {
      // this.pdfSrc =
      //   "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/ysxy.pdf";
      window.open(
        "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/ysxy.pdf"
      );
    },
    like1() {
      window.open(
        "https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/yhxy.pdf"
      );
    },
    wechatLogin() {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx49f83b5922c8cd91&redirect_uri=http%3A%2F%2Fxznc.mxlmyjy.com%2F%23%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
    // loginWithWechat() {
    //   this.$wechatLogin
    //     .wechatLogin()
    //     .then((res) => {
    //       // 登录成功后的处理逻辑
    //       console.log(res);
    //     })
    //     .catch((error) => {
    //       // 登录失败的处理逻辑
    //       console.error(error);
    //     });
    // },
    // imLogin(userId, userSig) {
    //   let loginPromise = this.tim.login({userID: userId, userSig: userSig});
    //   loginPromise.then(function (imResponse) {
    //     console.log(imResponse.data); // 登录成功
    //     if (imResponse.data.repeatLogin === true) {
    //       // 标识账号已登录，本次登录操作为重复登录。
    //       console.log(imResponse.data.errorInfo);
    //     }
    //   }).catch(function (imError) {
    //     console.warn('login error:', imError); // 登录失败的相关信息
    //   });
    // }
  },
};
</script>

<style scoped>
.loginBack {
  width: 750px;
  height: 100vh;
  background-image: url("../image/loginBack.png");
  background-size: 100% 100%;
  /* overflow: hidden; */
}
.loginBox {
  overflow: hidden;
}
.loginTitle {
  font-size: 48px;
  font-weight: 600;
  color: #3d3d3d;
  line-height: 21px;
  margin-left: 32px;
  margin-top: 208px;
}
.loginTab {
  display: flex;
  margin-top: 118px;
  margin-left: 32px;
}
.tabClass {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
}
.loginTab div:nth-child(2) {
  margin-left: 64px;
}
.active {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
}
.mobileStyle,
.CodeStyle {
  width: 686px;
  border-bottom: 2px solid #ebedf0;
  margin-left: 32px;
}
.mobileInp {
  outline: none;
  border: none;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 32px;
  margin-top: 64px;
  width: 686px;
}
.CodeInp {
  outline: none;
  border: none;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 32px;
  margin-top: 32px;
  width: 508px;
}
.CodeStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.codeDiv {
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
}
.loginBtn,
.loginBtn1 {
  width: 684px;
  height: 90px;
  background-color: #a2b3f3;
  border-radius: 16px;
  text-align: center;
  line-height: 90px;
  opacity: 1;
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 202px;
  margin-left: 32px;
}
.loginBtn1 {
  background: #0957c3;
  border-radius: 16px;
  opacity: 1;
}
.registerBtn {
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
  text-align: center;
  margin-top: 48px;
}
.other {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 126px;
}
.other div:nth-child(1) {
  width: 72px;
  height: 2px;
  background: #ebedf0;
  border-radius: 0px 0px 0px 0px;
}
.other div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 16px;
  margin-right: 16px;
}
.other div:nth-child(3) {
  width: 72px;
  height: 2px;
  background: #ebedf0;
  border-radius: 0px 0px 0px 0px;
}
.wxLogin {
  text-align: center;
}
.wxLogin img {
  width: 80px;
  height: 80px;
  margin-top: 32px;
}
.footer {
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 76px;
  margin-bottom: 80px;
}
.footer span {
  display: inline-block;
  color: #0957c3;
}
.showPassword img {
  width: 44px;
  height: 44px;
}
.codeColor {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
.blue {
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
  text-align: right;
  margin-right: 32px;
  margin-top: 32px;
}
</style>
